import { useCallback, useEffect } from 'react';

interface HookProps {
    open: boolean;
    setOpen: (open: boolean) => void;
}

const useCloseOnEsc = (props?: HookProps) => {
    const escHandler = useCallback(
        (event: KeyboardEvent) => {
            if (props?.open && event.keyCode === 27) {
                props.setOpen(false);
            }
        },
        [props]
    );
    useEffect(() => {
        window.addEventListener('keydown', escHandler);
        return () => window.removeEventListener('keydown', escHandler);
    }, [escHandler]);
};

export default useCloseOnEsc;
