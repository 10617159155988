import React, { createContext, FC, useContext, useCallback, useMemo, useState } from 'react';
import useSesame, { Sesame } from '../hooks/useSesame';
import triggerGAEvent from '../utils/triggerGAEvent';

const StepFormContext = createContext<{ open: boolean; initialValues: {} | undefined } | undefined>({
    open: false,
    initialValues: undefined,
});
const StepFormDispatchContext = createContext<
    (Omit<Sesame, 'open' | 'onOpen'> & { onOpen: (vals?: {}) => void }) | undefined
>(undefined);

const StepFormContextProvider: FC = ({ children }) => {
    const { open, ...actions } = useSesame();
    const [initialValues, setInitialValues] = useState<undefined | {}>(undefined);

    const onOpen = useCallback(
        (vals?: {}) => {
            actions.onOpen();
            if (vals) {
                setInitialValues(vals);
            }
        },
        [actions]
    );
    const onStepFormOpen = useCallback(
        (vals?: {}) => {
            onOpen(vals);
            triggerGAEvent('click', {
                event_category: 'Formulier',
                event_label: 'Stappen',
            });
        },
        [onOpen]
    );

    const onClose = useCallback(() => {
        setInitialValues(undefined);
        actions.onClose();
    }, [actions]);

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const value = useMemo(() => ({ ...actions, onOpen: onStepFormOpen, onClose }), []);

    return (
        <StepFormContext.Provider value={{ open, initialValues }}>
            <StepFormDispatchContext.Provider value={value}>{children}</StepFormDispatchContext.Provider>
        </StepFormContext.Provider>
    );
};

export const useStepFormDispatch = () => {
    const actions = useContext(StepFormDispatchContext);

    if (!actions) {
        throw new Error('useStepFormDispatch must be used within a StepFormContextProvider');
    }

    return actions;
};

export const useStepFormState = () => {
    const state = useContext(StepFormContext);
    if (state === undefined) {
        throw new Error('useStepFormState must be used within a StepFormContextProvider');
    }
    return state;
};

export const useStepForm = () => {
    const actions = useStepFormDispatch();
    const state = useStepFormState();

    return [state, actions] as const;
};

export default StepFormContextProvider;
