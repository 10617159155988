import { css } from 'styled-components';
import { TitleVariant } from '../components/atoms/Title';
import { BodyVariant } from '../components/atoms/Body';

export const mediaQueriesValues = {
    xs: 480,
    s: 688,
    m: 1024,
    l: 1200,
    xl: 1520,
    xxl: 1660,
};

interface Range {
    min: number;
    max: number;
}

export interface FontStyle {
    size: Range;
    line: Range;
    weight?: number;
    letterSpacing?: number;
}

const responsiveFontCss = css`
    font-size: calc(
        var(--min-size) * 1px + (var(--max-size) - var(--min-size)) * (100vw - (var(--min-viewport) * 1px)) /
            (var(--max-viewport) - var(--min-viewport))
    );
    line-height: calc(
        var(--min-line) * 1px + (var(--max-line) - var(--min-line)) * (100vw - (var(--min-viewport) * 1px)) /
            (var(--max-viewport) - var(--min-viewport))
    );

    @media (min-width: ${({ theme }) => theme.mediaQueriesValues.xl}px) {
        font-size: calc(var(--max-size) * 1px);
        line-height: calc(var(--max-line) * 1px);
    }
`;

export const getFontCss = (style: FontStyle) => {
    return css`
        --min-size: ${style.size.min};
        --max-size: ${style.size.max};
        --min-line: ${style.line.min};
        --max-line: ${style.line.max};
        ${responsiveFontCss};
        ${style.weight
            ? css`
                  font-weight: ${style.weight};
              `
            : ''}
        ${style.letterSpacing
            ? css`
                  letter-spacing: ${style.letterSpacing}px;
              `
            : ''}
    `;
};

const titleStyles: Record<TitleVariant, FontStyle> = {
    huge: {
        size: { min: 40, max: 60 },
        line: { min: 42, max: 56 },
        letterSpacing: 0,
        weight: 800,
    },
    large: {
        size: { min: 31, max: 42 },
        line: { min: 38, max: 42 },
        letterSpacing: 0,
        weight: 700,
    },
    regular: {
        size: { min: 19, max: 23 },
        line: { min: 24, max: 28 },
        letterSpacing: 0,
    },
    small: {
        size: { min: 16, max: 19 },
        line: { min: 24, max: 24 },
    },
    tiny: {
        size: { min: 16, max: 16 },
        line: { min: 20, max: 20 },
    },
    micro: {
        size: { min: 15, max: 15 },
        line: { min: 16, max: 16 },
    },
};

const bodyStyles: Record<BodyVariant, FontStyle> = {
    huge: {
        size: { min: 17, max: 21 },
        line: { min: 28, max: 32 },
        weight: 200,
    },
    large: {
        size: { min: 14, max: 18 },
        line: { min: 20, max: 28 },
        weight: 200,
    },
    regular: {
        size: { min: 14, max: 17 },
        line: { min: 20, max: 28 },
        letterSpacing: 0,
    },
    small: {
        size: { min: 13, max: 14 },
        line: { min: 20, max: 20 },
        letterSpacing: 0,
    },
    tiny: {
        size: { min: 12, max: 13 },
        line: { min: 20, max: 20 },
        letterSpacing: 0,
        weight: 600,
    },
    micro: {
        size: { min: 12, max: 12 },
        line: { min: 20, max: 20 },
        letterSpacing: 0,
    },
};

const spacing = {
    '00_None': '0px',
    '10_Micro': '2px',
    '20_Tiny': '4px',
    '30_Small': '8px',
    '40_Standard': '16px',
    '50_Semi': '32px',
    '60_Large': '40px',
    '70_XLarge': '48px',
    '80_XXLarge': '56px',
} as const;

const theme = {
    spacing,
    space: Object.values(spacing),
    colors: {
        neutral: [
            '#ffffff',
            '#fbfcfc',
            '#f7f8f9',
            '#e6e9eb',
            '#cbd0d4',
            '#a4acb2',
            '#48515b',
            '#212a36',
            '#000208',
        ].reverse(),
        primary: ['#020303', '#0f1d19', '#1c3730', '#2a5147', '#376b5d', '#448574', '#57b89b', '#c2dfd8', '#e8f3f0'],
        secondary: ['#480000', '#6a0800', '#852303', '#9f3a11', '#ba5127', '#d6683c', '#f28052', '#ffa070', '#ffc592'],
    },
    breakpoints: Object.values(mediaQueriesValues).map(value => `${value}px`),
    mediaQueries: Object.assign(
        {},
        ...Object.entries(mediaQueriesValues).map(([key, value]) => ({
            [key]: `${value}px`,
        }))
    ),
    fontSizes: {
        title: titleStyles,
        body: bodyStyles,
    },
    mediaQueriesValues,
} as const;

export default theme;

export type Theme = typeof theme;
