import React, { createContext, FC, useContext, useCallback, useMemo } from 'react';
import useSesame, { Sesame } from '../hooks/useSesame';
import triggerGAEvent from '../utils/triggerGAEvent';

const ContactFormContext = createContext<boolean | undefined>(undefined);
const ContactFormDispatchContext = createContext<Omit<Sesame, 'open'> | undefined>(undefined);

const ContactFormContextProvider: FC = ({ children }) => {
    const { open, ...actions } = useSesame();

    const onStepFormOpen = useCallback(() => {
        actions.onOpen();
        triggerGAEvent('click', {
            event_category: 'Formulier',
            event_label: 'Contact',
        });
    }, [actions]);

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const value = useMemo(() => ({ ...actions, onOpen: onStepFormOpen }), []);
    return (
        <ContactFormContext.Provider value={open}>
            <ContactFormDispatchContext.Provider value={value}>{children}</ContactFormDispatchContext.Provider>
        </ContactFormContext.Provider>
    );
};

export const useContactFormDispatch = () => {
    const actions = useContext(ContactFormDispatchContext);

    if (!actions) {
        throw new Error('useContactFormDispatch must be used within a ContactFormContextProvider');
    }

    return actions;
};

export const useContactFormState = () => {
    const state = useContext(ContactFormContext);
    if (state === undefined) {
        throw new Error('useContactFormState must be used within a ContactFormContextProvider');
    }
    return state;
};

export const useContactForm = () => {
    const actions = useContactFormDispatch();
    const state = useContactFormState();

    return [state, actions] as const;
};

export default ContactFormContextProvider;
