/* eslint-disable react/jsx-filename-extension, import/prefer-default-export */

import React from 'react';
import { ThemeProvider } from 'styled-components';
import DeviceSizeProvider from '../context/devicesize';
import ContactFormContextProvider from '../context/contactform';
import theme from '../constants/theme';
import { WrapRootElementBrowserArgs } from 'gatsby';
import StepFormContextProvider from '../context/stepform';

export default ({ element }: WrapRootElementBrowserArgs) => (
    <DeviceSizeProvider>
        <ContactFormContextProvider>
            <StepFormContextProvider>
                <ThemeProvider theme={theme}>{element}</ThemeProvider>
            </StepFormContextProvider>
        </ContactFormContextProvider>
    </DeviceSizeProvider>
);
