import { useCallback, useRef, useState } from 'react';
import useOnClickOutside from 'use-onclickoutside';
import useCloseOnEsc from './useCloseOnEsc';

export interface SesameOptions {
    closeOnClickOutside?: boolean;
    closeOnEscape?: boolean;
}

/**
 *
 * This hook can be used for certain scenarios which use common actions like the modal for example. These actions
 * are wrapped in useCallbacks in order to avoid unnecessary rerenders.
 *
 * @param initialValue the initial state value. The default is false
 */

/**
 * This hook can be used for certain scenarios which use common actions like the modal for example. These actions
 * are wrapped in useCallbacks in order to avoid unnecessary rerenders.
 *
 * @param {Boolean} initialValue the initial state value. The default is false
 * @param {Object} options options - options object
 * @param {Boolean} options.closeOnClickOutside closeOnClickOutside - needs ref to be set in order for closing when clicking outside that ref
 * @param {Boolean} options.closeOnEscape closeOnEscape - close when the user presses the escape key
 */
const useSesame = (
    initialValue = false,
    options: SesameOptions = { closeOnClickOutside: false, closeOnEscape: false }
) => {
    const { closeOnClickOutside, closeOnEscape } = options;
    const [open, setOpen] = useState(initialValue);
    const onClose = useCallback(() => setOpen(false), []);
    const onOpen = useCallback(() => setOpen(true), []);
    const onToggle = useCallback(() => setOpen(prevOpen => !prevOpen), []);

    const ref = useRef(null);

    useCloseOnEsc(closeOnEscape ? { open, setOpen } : undefined);

    useOnClickOutside(ref, () => {
        if (closeOnClickOutside) {
            setOpen(false);
        }
    });

    return { open, onOpen, onClose, onToggle, ref };
};

export default useSesame;

export type Sesame = ReturnType<typeof useSesame>;
